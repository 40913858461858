@import url("https://use.typekit.net/wzg8tjf.css");
@font-face {
    font-family: 'tt_commons';
    font-style: normal;
    font-weight: 400;
    src: url(https://premium-storefronts.s3.amazonaws.com/storefronts/shopbetrapp/fonts/tt_commons_regular.woff2) format('woff2'),
    url(https://premium-storefronts.s3.amazonaws.com/storefronts/shopbetrapp/fonts/tt_commons_regular.woff) format('woff');
}

@font-face {
    font-family: 'tt_commons';
    font-style: normal;
    font-weight: 500;
    src: url(https://premium-storefronts.s3.amazonaws.com/storefronts/shopbetrapp/fonts/tt_commons_medium.woff2) format('woff2'),
         url(https://premium-storefronts.s3.amazonaws.com/storefronts/shopbetrapp/fonts/tt_commons_medium.woff) format('woff');
}

@font-face {
    font-family: 'tt_commons';
    font-style: normal;
    font-weight: 600;
    src: url(https://premium-storefronts.s3.amazonaws.com/storefronts/shopbetrapp/fonts/tt_commons_demibold.woff2) format('woff2'),
         url(https://premium-storefronts.s3.amazonaws.com/storefronts/shopbetrapp/fonts/tt_commons_demibold.woff) format('woff');
}

@font-face {
    font-family: 'tt_commons';
    font-style: normal;
    font-weight: 700;
    src: url(https://premium-storefronts.s3.amazonaws.com/storefronts/shopbetrapp/fonts/tt_commons_bold.woff2) format('woff2'),
         url(https://premium-storefronts.s3.amazonaws.com/storefronts/shopbetrapp/fonts/tt_commons_bold.woff) format('woff');
}

:root {
    --body-font-family: 'tt_commons', -apple-system, "Helvetica Neue", Roboto, Helvetica, Arial, sans-serif !important;
    --blue: #9F0CCC !important;
    --heading-font-family: 'tt_commons', -apple-system, "Helvetica Neue", Roboto, Helvetica, Arial, sans-serif !important;
}

/* Dark theme overrides */

body {
    background-color: $pdpBlack;
    font-family: 'tt_commons', sans-serif !important;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, input, textarea {
    font-family: 'tt_commons', sans-serif !important;
}

.universal.footer {
    background-color: $pdpBlack !important;
}

.tile-heading, .relatedgrid h3, .shopping-cart-heading, .cart-item-title a, .cart-summary-label, .cart-item-price .price, .product-detail-tabs__content, .color-label, .cart-item__controls .pr_inputfield input, .products_carousel h3 {
    color: $white;
}

.cart-item-info .cart-item-remove-btn {
    color: #acacac;
}

.listing-details p, .listing-details .form__label, .pr_tabs__item span, .cart-item-product-name, .typ--link, .cart-summary-note, .product-tile-title, .sizechart__modal-btn button, .theme--light .product-detail-tabs .product-detail-tabs__content, .cart-item-variation {
color: #BFBFBF;
}

.relatedgrid h3, .shopping-cart-heading, .listing-header__title, .products_carousel h3 {
    font-family: 'tt_commons', sans-serif !important;
    font-weight: 700;
}

.listing-details p, .pr_tabs__item span, .product-detail-tabs__content, .product-tile-title, .footer__menu a, .footer__bottomright, .footer__copyright a {
    font-size: 1.6rem;
}

.product-tile-details {
    color: $white;
}

.listing-details .listing-header__title, .listing-header__price span {
    color: $white !important;
}

.products_carousel_wrapper::after, .products_carousel_wrapper::before {
    display: none;
}

.listing-details {
    border: none;
}

.physical_return__details {
    background-color: rgba(255, 255, 255, 0.07);
    color: $white !important;
    border: none !important;
    border-radius: 8px;
}

.physical_return__details strong, .physical_return__details a {
    color: $white !important;
}

.pr_tabs {
    border-top: initial !important;
    border-bottom: 1px solid rgba(217, 217, 217, 0.4) !important;
}

.pr_tabs__item.is-active span:after {
    background-color: $pdpRed !important;
}

.pr_tabs__item.is-active span {
    color: $white !important;
}

.product-detail-tabs .pr_tabs__item {
    margin-bottom: -4px;
}

.variantselector__grid.size .variantselector__item, .variantselector__grid.size {
    border-color: $pdpBlack;
}

.variantselector__grid.size .variantselector__item {
    background: rgba(255, 255, 255, 0.07);
    color: #fff;
    height: 48px;
}

.variantselector__item.is-active .variantselector__size {
    background-color: $white !important;
    color: $pdpBlack;
    font-weight: 600;
    height: 48px;
}

.variantselector__grid.size.underfill .variantselector__item {
    border-top: none;
}

.pr_selectfield__toggle {
    background-color: hsla(0,0%,100%,.07) !important;
    border: 1px solid #3c3c3c !important;
    color: $white !important;
}

.checkoutsection .pr_selectfield__toggle {
    color: initial !important;
}

.checkoutsection .pr_selectfield__toggle:after {
    border-color: #000 !important;
}


div:not(.listing__addcart--mobile) .listing-order-form .pr_inputfield__label--animated,div:not(.listing__addcart--mobile) .singleselector__label, .cart-item__controls .pr_inputfield__label--animated {
    background-color: #262626 !important;
    border-radius: 8px;
    color: $white !important;
}

.listing-order-form .singleselector {
    color: #fff;
}

.pr_selectfield__toggle:after {
    border-color: $white !important;
}

.pr_modal__inner {
    .pr_selectfield__selected_item {
        color: #000 !important;
    }
    .pr_selectfield__toggle:after {
        border-color: #000 !important;
    }
}

/* theme overrides */

/* header */

header.universal {
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.header__actions .socialicons {
    display: none;
}

.header-menu {
    gap: 32px;
}

.header-menu .menu-item.selected-menu-item {
    box-shadow: 0px 2px 0px 0px #9F0CCC;
}

.header-menu .menu-item a {
    padding: 0.7rem 0;
    font-family: var(--body-font-family);
    font-size: 1.6rem;
    font-weight: 500;
}

.pr_btn--md, .pr_btn {
    border-radius: 8px;
    font-family: var(--body-font-family);
    font-size: 1.6rem !important;
    font-weight: 500;
}

.header.universal .header__logo a, .header.universal .header__logo img {
    height: 32px;
}

/* search */ 

.header__search {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.04);
    border: none;
    font-family: var(--body-font-family);
    font-size: 1.6rem;
    font-weight: 500;
    color: #BCBCBC;
}

/* General */


.listing__options.sizes {
    .pr_inputfield__label {
        display: none;
    }
    @include breakpoint($mobile-lg) {
        .pr_inputfield__label {
            display: initial;
        }
    }
}

.listing-gallery-viewport, .listing-gallery-menu-item {
    border: none;
    border-radius: 12px;
}

.product-tile-image > div {
    border-radius: inherit;
    border: none;
}

.report__wrapper {
    display: none;
}

.pr_btn {
    font-weight: 500 !important;
}

.pr_btn.btn--primary {
    background-color: $pdpRed;
    color: $white;
    border-color: $pdpRed;
    font-weight: 500;
    font-family: var(--body-font-family);
    font-size: 1.6rem;
}

/* hero */
.hero__inner {
    aspect-ratio: 11/4;
    padding-bottom: initial !important;
    @media only screen and (min-width: 989px) {
        max-width: 136.3rem;
        margin: 0 auto;
        padding: 1.5rem 3em 0;
        margin-top: 32px;
        border-radius: 8px;

    }
}

/* footer */

.footer__bottomright span {
    color: #9E9E9E;
}

.footer__newsletter {
    text-align: center;
}

.footer__newsletter form {
    margin: auto;
}

.footer__newsletter h5, .heading__block h3 {
    font-family: 'tt_commons', sans-serif !important;
    font-weight: 700;
    font-size: 36px;
}

.footer__newsletter h5 {
    font-size: 32px !important;
}

.footer__inner {
    grid-template-areas: "menu newsletter about" !important;

    @include breakpoint($mobile-lg) {
        display: inline;
    }
}

.footer__inner input[type="email"] {
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 8px 0px 0px 8px;
    padding: 10px 18px;
    color: $white;
    width: 225px;
}

.footer__bottom__inner {
    align-items: bottom;

    @include breakpoint($mobile-lg) {
        align-items: center;
        text-align: center;
        display: flex;
        justify-content: center;
    }
}

#hsForm_fbf727bd-7036-4708-bf68-4d5c0042ed24{
    margin: auto;
}
.hs-button {
    background-color: $pdpRed !important;
    padding: 10px 18px;
    border-radius: 0px 8px 8px 0px;
    color: $white;
    width:  max-content;
}
.hs-button:hover {
    background-color: $pdpRed !important;
    opacity: 0.8;
}

.hs-error-msg {
    font-size: 12px;
}

.relatedgrid_container {
    display: none;
}

.relatedgrid__products {
    justify-content: initial;
}

.color .variantselector__item.is-active:after {
        top: -1px;
        left: 21%;
}


.pr_modal__inner .submitted-message.hs-main-font-element p {
    color: #000;
}

.skeleton {
    opacity: .7;
    animation: dark-skeleton-loader 1s linear infinite alternate !important; 
}

.pr_btn.btn--primary:hover {
    background-color: $pdpRedDark !important;
    border-color: $pdpRedDark !important;
}

.product-tile-image {
    background-color: #282828 !important;
    transition: linear 0.2s;
    border-radius: 12px;
}

.listing-gallery-viewport {
    background-color: #373737 !important;
}

.listing-gallery-viewport img, .listing-gallery-menu-item img {
    background-color: #373737 !important;
}

.cart-item-img {
    background-color: #373737 !important;
    border: none;
}

.listing-gallery-menu-item.is-active {
    border: solid 1px $pdpRed !important;
    border-radius: 8px;
}

.listing-gallery-menu-item {
    border-radius: 8px;
}

/* .product-tile-image:hover {
    background-color: $pdpRed !important;
} */
  
@keyframes dark-skeleton-loader {
    0% {
        background-color: #373737; 
    }
    100% {
        background-color: #242424;
    } 
}

.footer__localization span.mr2 {
    display: none;
}

.localizationcontrols {
    border: 1px solid #262626;
    padding: 10px 18px;
    border-radius: 16px;
}

.footer__localization button > span {
    display: contents;
    padding: 16px;
}

.footer__localization button {
    gap: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer__about {
    max-width: 100%;
    width: 100%;
}

@media only screen and (min-width: 769px) {
    .footer__about .socialicons {
        justify-content: end !important;
    }
}

.footer__about .socialicons svg {
    width: 32px;
    height: 32px;
}

.footer__newsletter p {
    color: $textGrey;
}

.pr_modal__content {
    width: auto;
    @include breakpoint($mobile-lg) { 
        min-width: 100%;
    }
}

.pr_modal__contentwrapper {
    width: 100%;
}
    
.cartconfirm__total {
    margin-left: -24px;
    margin-right: -24px;
    padding: 16px 24px;
    background: #f0f0f0;
    @include breakpoint($mobile-lg) {
        margin-bottom: 48px !important;
    }
}

.listing__addcart--mobile .singleselector {
    color: #000 !important;
}

.listing__addcart--mobile .pr_selectfield__selected_item {
    color: #000 !important;
}

.listing__addcart--mobile .pr_selectfield__toggle:before, .listing__addcart--mobile .pr_selectfield__toggle:after {
    border-color: #000 !important;
}

.listing__addcart--mobile .sizechart__modal-btn button {
    color: #000;
}



/* modal */

.getnotified .pr_modal__content {
    flex-direction: column;
}

button.getnotified {
    padding: 10px 14px 10px 12px;
    border-radius: 8px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.15);
    font-weight: 500;
    gap: 8px;
    display: flex;
}
button.getnotified span {
    font-weight: 500;
    font-family: var(--body-font-family) !important;
    font-size: 1.6rem;
}

@include breakpoint ($mobile-lg) {

    button.getnotified {
        padding: 10px 12px !important;
    }
    
    button.getnotified span {
        display: none;
    }
}

button.getnotified:hover {
    background-color: #0F0F0F;
    opacity: 0.8;
}

.getnotified input {
    background-color: rgba(255, 255, 255, 0.07) !important;
    border: 1px solid #3c3c3c !important;
    color: #000;
    width: 100%;
    text-align: center;
    border-radius: 16px;
    padding-left: 12px;
    height: 50px;
    padding-right: 12px;
    font-size: 16px;
}

.getnotified .actions input {
    border: none !important;
    margin-top: 12px;
    border-radius: 200px;
    color: #fff;
    font-weight: 500;
    background-color: $pdpRed !important;
}

.getnotified .hs-error-msg {
    color: red;
}

/* search overrides */

.search-results .product-tile-details, .search-results .product-tile-title, .search-results .product-tile-category   {
    color: #000 !important;
}

.search-results .product-tile-image {
    background-color: #37373724 !important;
}

.search-results .product-tile-image:hover {
    background-color: $pdpRed !important;
}

/* homepage hide category titles */
.flickity-slider .product-tile .product-tile-link-wrapper .product-tile-details .product-tile-info .product-tile-category  {
    display: initial !important;
}

.product-tile .product-tile-link-wrapper .product-tile-details .product-tile-info .product-tile-category  {
    display: none !important;
}

.product-tile-info {
    margin-right: 12px;
}

.products_carousel .pr_disabled__prevbtn .pr_carousel__prevbtn .pr_btn, .products_carousel .pr_disabled__nextbtn .pr_carousel__nextbtn .pr_btn {
    background-color: transparent !important;
    opacity: 0.4;
}

.product-tile-image .product-tile-quick-add-to-cart-btn {
    border-radius: 100% !important;
    border: none !important;
    width: 32px !important;
    height: 32px;
}

.h3, .typ--h3 {
    font-weight: 700;
}

/* better hamburger toggle */

.hamburger-button--minus.is-active .hamburger-button-inner::after {
    bottom: 0;
    transform: rotate(-45deg);
    background-color: white;
    opacity: 1;
}

.hamburger-button--minus.is-active .hamburger-button-inner::before {
    top: 0;
    transform: rotate(45deg);
    background-color: white;
    opacity: 1;
}

.hamburger-button.is-active .hamburger-button-inner {
    background-color: transparent !important;
}

.header.universal .header__hamburger {
    position: initial;
}

.header__hamburger .flyout-menu {
    left: 0;
    right: 0;
    width: 100vw;
    padding: 2rem 2.4rem;
    border-radius: 0 0 12px 12px;
    padding-bottom: 40px;
    .flyout-menu-item a {
        font-size: 24px;
        text-decoration: none !important;
        font-weight: 600 !important;
        text-align: left;
        height: 55px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    .socialicons svg {
        height: 32px;
        width: 32px;
    }
    .custom-accordion-class .pr_accordion__icon {
        display: none;
    }

    .custom-accordion-class .pr_accordion__content {
        display: none;
    }
    .pr_accordion {
        border-bottom: none;
    }
    
    .flyout-menu-item a:after {
        content: '';
        width: 32px;
        height: 32px;
        background-image: url("chevron-right.svg");
        background-repeat: no-repeat;
        background-position: right;
    }
}

.page-content.handshake-bets .tile-heading h2 {
    width: 100% !important;
}

.page-content.handshake-bets .tile-heading h2:before {
    content: '';
    width: 100%;
    background-image: url("handshake-bets-banner.png");
    display: block;
    padding-bottom: 20.3%;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 24px;
}

@include breakpoint ($mobile-md) {
    .page-content.handshake-bets .tile-heading h2:before {
        background-image: url("handshake-bets-banner-mob.png");
        padding-bottom: 90%;
    }
}

.page-content.handshake-bets .collection-content.content {
    margin-top: 0px;
}
